import "./EurekaFeaturesSection.css";
import React, { useState } from "react";
import { InView } from "react-intersection-observer";

function EurekaFeaturesSection({data}) {
  const [inView1, setInView1] = useState(false);
  const [inView2, setInView2] = useState(false);
  const [inView3, setInView3] = useState(false);

  return (
    <section className="features-section__container" >
      <div className="features-section__content">
        <ul className="features-section__list">
          <InView onChange={setInView1}>
            <li
              className={
                "features-section__list-element" + (inView1 ? " active" : "")
              }
            >
              {data.featureOne}
            </li>
          </InView>
          <InView onChange={setInView2}>
            <li
              className={
                "features-section__list-element features-section__list-element--even" +
                (inView2 ? " active" : "")
              }
            >
               {data.featureTwo}
            </li>
          </InView>
          <InView onChange={setInView3}>
            <li
              className={
                "features-section__list-element" + (inView3 ? " active" : "")
              }
            >
              {data.featureThree}
            </li>
          </InView>
        </ul>
      </div>
      <div className="features-section__slogan-wrapper">
        <p className="features-section__slogan">
           {data.text}
            <br/>
            <a 
                href= {data.link}
                className="features-section__slogan-link"
            >
               {data.link}
            </a>
        </p>
      </div>
    </section>
  );
}

export default EurekaFeaturesSection;
