import React from 'react';
import './AboutUsTeamSection.css';
import renata from './zdj_Renata.jpg'
import rhonda from './zdj_Rhonda.jpg'
import {useState } from "react";
import { InView } from "react-intersection-observer";

function AboutUsTeamSection() {
  const [inViewFirst, setInViewFirst] = useState(false);
  const [inViewSecond, setInViewSecond] = useState(false);

  return (
    <section className="aboutUs-team__container">

    <div className='aboutUs-team__description-wrapper' >
        <img src={rhonda}  className='aboutUs-team__img' />
        <div className="aboutUs-team__description aboutUs-team__description--left ">
            <h2 className="aboutUs-team__title aboutUs-team__title--left ">RHONDA</h2>
            <h3 className='aboutUs-team__subtitle--left' >MANAGING DIRECTOR</h3>
            <InView onChange={setInViewSecond}>
            <p className={"aboutUs-team__text aboutUs-team__text--right" + (inViewSecond ? " active" : "")}>
            Rhonda is a former teacher turned data scientist/engineer. Her more recent
             work in data science has included building a web-based voter data tracking 
             platform, anomaly detection models for skin lesions and eye disease, and a 
             satellite imagery pipeline for NASA data, among other things. She also 
             supported curriculum development at Illustrative Mathematics. Prior to her 
             work in technology, she taught English Language Arts pre-kindergarten 
             through high school.
            </p>
            </InView>
        </div>
    </div>

    <div className='aboutUs-team__description-wrapper' >
        <div className="aboutUs-team__description aboutUs-team__description--right">
            <h2 className="aboutUs-team__title aboutUs-team__title--right ">RENATA</h2>
            <h3 className='aboutUs-team__subtitle--right' >PROGRAM DIRECTOR</h3>
            <InView onChange={setInViewFirst}>
            <p className={"aboutUs-team__text aboutUs-team__text--left" + (inViewFirst ? " active" : "")}>
            Renata brings experience in strategy, development, and operations to impact in education. 
            Previously, she has worked in the United States and internationally within non-profit 
            organizations, government and multilateral actors, and philanthropy
            </p>
            </InView>
        </div>
        <img src={renata}  className='aboutUs-team__img' />
      </div>

    </section>
  );
}

export default AboutUsTeamSection;