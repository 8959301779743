import {
  TransparentButton,
  TransparentHashButton,
} from "../../Common/Buttons/Buttons";
import "./HeroSection.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HeroSection({data}) {
  return (
    <section className="hero-section__container">
      <div className="hero-section__button-container">
        <p className="hero-section__slogan">
        {data.title}
        </p>
        <TransparentHashButton path="#about-us">
        {data.subtitle}
          <FontAwesomeIcon
            className="hero-section__arrow-icon"
            icon={faArrowRight}
          />
        </TransparentHashButton>
      </div>
    </section>
  );
}

export default HeroSection;
