import { useEffect, useState } from "react";
import React from "react";
import { InView } from "react-intersection-observer";
import "./EurekaAboutSection.css";

function EurekaAboutSection({data}) {
  const [inViewFirst, setInViewFirst] = useState(false);
  const [inViewSecond, setInViewSecond] = useState(false);
  const [inViewThird, setInViewThird] = useState(false);

  return (
    <section className="eurekaSlogan__container" id="about-us">
      <div className="eurekaSlogan__content eurekaSlogan__content--right">
        <h2 className="eurekaSlogan__page-title eurekaSlogan__page-title--right ">
        {data.paragraphOneTitle}
        </h2>
        <InView onChange={setInViewFirst}>
          <p
            className={
              "eurekaSlogan__text eurekaSlogan__text--left" +
              (inViewFirst ? " active" : "")
            }
          >
               {data.paragraphOneText}
          </p>
        </InView>
      </div>
      <div className="eurekaSlogan__content eurekaSlogan__content--left ">
        <h2 className="eurekaSlogan__page-title eurekaSlogan__page-title--left ">
        {data.paragraphTwoTitle}
        </h2>
        <InView onChange={setInViewSecond}>
          <p
            className={
              "eurekaSlogan__text eurekaSlogan__text--right" +
              (inViewSecond ? " active" : "")
            }
          >
            {data.paragraphTwoText}
          </p>
        </InView>
      </div>
      <div className="eurekaSlogan__content eurekaSlogan__content--right">
        <h2 className="eurekaSlogan__page-title eurekaSlogan__page-title--right ">
        {data.paragraphThreeTitle}
        </h2>
        <InView onChange={setInViewThird}>
          <p
            className={
              "eurekaSlogan__text eurekaSlogan__text--left" +
              (inViewThird ? " active" : "")
            }
          >
            {data.paragraphThreeText}
          </p>
        </InView>
      </div>
    </section>
  );
}

export default EurekaAboutSection;
