import { useState } from "react";
import { ColouredSubmitButton } from "../../Common/Buttons/Buttons";
import Modal from "../../Common/Modal/Modal";
import "./ContactSection.css";

function ContactSection({data}) {
  const [contactName, setContactName] = useState("");
  const [contactSurname, setContactSurname] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    setContactName("");
    setContactSurname("");
    setContactEmail("");
    setContactMessage("");
    setIsModalOpen(!isModalOpen);
  };

  return (
    <section className="contact-section__container" id="contact">
      <h3 className="contact-section__page-title">{data.title}</h3>
      <form className="contact-section__form" onSubmit={submitHandler}>
        <div className="contact-section__form-inner-wrapper">
          <div className="contact-section__input-wrapper">
            <input
              className="contact-section__input"
              placeholder={data.name}
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            ></input>
            <input
              className="contact-section__input"
              placeholder={data.lastName}
              value={contactSurname}
              onChange={(e) => setContactSurname(e.target.value)}
              required
            ></input>
            <input
              className="contact-section__input"
              placeholder={data.email}
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              type="email"
              required
            ></input>
          </div>

          <textarea
            className="contact-section__textarea"
            placeholder={data.message}
            value={contactMessage}
            onChange={(e) => setContactMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <ColouredSubmitButton>{data.submit}</ColouredSubmitButton>
      </form>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </section>
  );
}

export default ContactSection;
