import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HomePage from "./views/HomePage/HomePage";
import EurekaPage from "./views/Eureka/EurekaPage";
import Navbar from "./components/Common/Navbar/Navbar";
import OtherProjectsPage from "./views/OtherProjects/OtherProjectsPage";
import AboutUsPage from "./views/AboutUs/AboutUsPage";
import CareerPage from "./views/Career/CareerPage";
import Footer from "./components/Common/Footer/Footer";

import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { LanguageContext } from "./contexts/LanguageContext";
import { polish } from "./data/polish";
import { english } from "./data/english";

function App() {
  let location = useLocation().pathname;
  const navigate = useNavigate();

  let { lang, setLang } = useContext(LanguageContext);
  const [dataByLanguage, setDataByLanguage] = useState(english);

  useEffect(() => {
    if (lang !== "pl" && lang !== "en") {
      setLang(
        navigator.language.split("-")[0] || navigator.userLanguage.split("-")[0]
      );

      lang =
        navigator.language.split("-")[0] ||
        navigator.userLanguage.split("-")[0];

      location = location.split("/")[2] ? location.split("/")[2] : "";   

      if (location.length > 2) {
        navigate(`/${lang}/${location}`);
      } else navigate(`/${lang}`);
    }
    const languageData = lang === "pl" ? polish : english;
    setDataByLanguage(languageData);

  }, [lang, setLang, navigate, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App" id="App">
      <Navbar data={dataByLanguage.navbar} />
      <Routes>
        <Route
          exact
          path="*"
          element={<HomePage data={dataByLanguage.homePage} />}
        />
        <Route
          exact
          path="/eureka"
          element={<EurekaPage data={dataByLanguage.eureka} />}
        />
        <Route
          exact
          path="other-projects"
          element={<OtherProjectsPage data={dataByLanguage.otherProject} />}
        />
        <Route
          exact
          path="/about-us"
          element={<AboutUsPage data={dataByLanguage.about} />}
        />
        <Route
          exact
          path="/career"
          element={<CareerPage data={dataByLanguage.career} />}
        />
        <Route
          exact
          path="/:id"
          element={<HomePage data={dataByLanguage.homePage} />}
        />
        <Route
          exact
          path="/:id/eureka"
          element={<EurekaPage data={dataByLanguage.eureka} />}
        />
        <Route
          exact
          path="/:id/other-projects"
          element={<OtherProjectsPage data={dataByLanguage.otherProject} />}
        />
        <Route
          exact
          path="/:id/about-us"
          element={<AboutUsPage data={dataByLanguage.about} />}
        />
        <Route
          exact
          path="/:id/career"
          element={<CareerPage data={dataByLanguage.career} />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
