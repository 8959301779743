import { useEffect } from "react";
import AboutSection from "../../components/HomePageComponents/AboutSection/AboutSection";
import ContactSection from "../../components/HomePageComponents/ContactSection/ContactSection";
import HeroSection from "../../components/HomePageComponents/HeroSection/HeroSection";
import OfferSection from "../../components/HomePageComponents/OfferSection/OfferSection";
import "./HomePage.css";

function HomePage({data}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <HeroSection data={data.heroSection}/>
      <AboutSection data={data.aboutSection}/>
      <OfferSection data={data.offerSection}/>
      <ContactSection data={data.contactSection}/>
    </div>
  );
}

export default HomePage;
