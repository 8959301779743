import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Buttons.css";

export function TransparentButton(props) {
  return (
    <Link to={props.path}>
      <button className="btn transparent-btn">{props.children}</button>
    </Link>
  );
}

export function TransparentHashButton(props) {
  return (
    <HashLink smooth to={props.path}>
      <button className="btn transparent-btn">{props.children}</button>
    </HashLink>
  );
}

export function ColouredSubmitButton(props) {
  return (
    <button type="submit" className="btn coloured-btn">
      {props.children}
    </button>
  );
}
