import React from "react";
import "./EurekaSloganSection.css";

function EurekaSloganSection({data}) {
  return (
    <section className="eurekaSlogan__container">
      <div className="eurekaSlogan__slogan-wrapper">
        <p className="eurekaSlogan__slogan">
        {data.text}
        </p>
      </div>
    </section>
  );
}

export default EurekaSloganSection;
