import React from "react";
import "./CareerJobOffers.css";

export default function CareerJobOffers({data}) {
  return (
    <section className="careerJobOffers__container">
      <h2 className="careerJobOffers__Sectionheadline">{data.title}</h2>
      <div className="careerJobOffers__content careerJobOffers__content--blue ">
        <h3 className="careerJobOffers__headline">{data.paragraphOneTitle}</h3>
        <ul className="careerJobOffers__list">
          <li>{data.paragraphOneLiOne}</li>
          <li>{data.paragraphOneLiTwo}</li>
        </ul>
      </div>
      <div className="careerJobOffers__content careerJobOffers__content--blue ">
        <h3 className="careerJobOffers__headline">{data.paragraphTwoTitle}</h3>
        <ul className="careerJobOffers__list">
          <li>{data.paragraphTwoLiOne}</li>
        </ul>
      </div>
      <div className="careerJobOffers__content careerJobOffers__content--blue ">
        <h3 className="careerJobOffers__headline">{data.paragraphThreeTitle}</h3>
        <ul className="careerJobOffers__list">
          <li>{data.paragraphThreeLiOne}</li>
          <li>{data.paragraphThreeLiTwo}</li>
        </ul>
      </div>
      <div className="careerJobOffers__GetInTouchContainer">
        <p className="careerJobOffers__GetInTouch">
        {data.textUp}
        </p>
        <p className="careerJobOffers__SmallText">
        {data.textDown}
        </p>
      </div>
    </section>
  );
}
