
import polishFlag from "../data/languages/polski_o.png"
import englishFlag from "../data/languages/angielski.png"

export const english = {
    navbar: {
      links: ["About us",  "Career", "Contact"],
      aboutUs: ["Our Products","Eureka", "Other Projects"],
      languages: {link: polishFlag, lang: "Polski"}
    },
    homePage: {
      heroSection: {
        title: "Speak a new language with confidence.",
        subtitle: "See more",
      },
      aboutSection: {
        tittle: "About us",
        subtitle: `Our products enable you to learn foreign languages easily and naturally,
              playfully and without straining, without strict teachers and a lot of homework,
              anywhere, anytime. We are a fantastic team of talented people who love their
              work. We strongly believe that only happy people can create high-quality and
              useful applications, and our company does everything possible for each
              employee to reveal their talent, feel confident, and comfortable. We believe that
              it is important to be heard and directly influence product development.`,
        catchWords: `European Education Agency is dedicated to changing
              people's lives through the power of language.`,
      },
      offerSection: {
        title: "Learners who take our courses experience a lift in:",
        paragrafOne: "Expanding vocabulary",
        paragrafTwo: "Perfecting grammar",
        paragrafThree: "Bolstering writing skills",
        paragrafFour: "Improving reading skills",
        text: `In today’s world, online
          courses are the most
          effective way to learn a
          new language.`,
      },
      contactSection: {
        title: "Contact us",
        name: "Name",
        lastName: "Last name",
        email: "e-mail adress",
        message: "Message content",
        submit: "Send",
      },
    },
    eureka: {
      hero: {
        headline: "Eureka",
      },
      slogan: {
        text: `Combining the best of AI and language science, lessons
          are tailored to help you learn at just the right level and
          pace.`,
      },
      about: {
        paragraphOneTitle: `Learning a language isn't
          hard`,
        paragraphOneText: `Learn how to navigate real, everyday situations in your new language. You'll only
          learn practical and useful skills that you can apply right away and quickly meet
          your goal of having real-world conversations.`,
        paragraphTwoTitle: `Short lessons. Real-life
          phrases.`,
        paragraphTwoText: `Everything you need to go from learning to speaking, fast.`,
        paragraphThreeTitle: `Language learning that
          works`,
        paragraphThreeText: `Immersive learning that makes you feel like you're right there with the locals
          Learn more, faster, with a learning algorithm developed with the best cognitive
          science. Find the content topics to match what you need: from holiday small talk
          to meeting your partner’s family.`,
      },
      features: {
        featureOne: "One account",
        featureTwo: "3 Levels",
        featureThree: "Multiple devices",
        text: `Would you like to find
          out more?`,
        link: "https://eureka-online.eu/en"  
      },
    },
    otherProject: {
      hero: { headline: "Stay tuned!" },
      about: {
        title: "Other Projects",
        text: `We work so hard, in part, because we feel that we are part of something bigger
        than ourselves. We believe that the mission of the organization aligns to our own
        values and goals, and that our work is an important part of achieving that larger
        institutional mission.`,
      },
      progress: {
        text: `We are working on
        something awesome.
        Stay tuned!`,
      },
    },
    about: {
      hero: { headline: "About us" },
      about: {
        textUp: `Our mission is to make the language learning process accessible to everyone.`,
        textDown: `In each employee we value their individuality, independent opinion, the ability to take responsibility
           for solving issues and problems. Everyone has their own area of responsibility. Uncompromising
           quality, cooperation, fresh ideas, friendly atmosphere of the company – all these things influence
           our products and their creation.`,
      },
      company: {
          title: `We work as a team`,
        text: `We’re fun, curious about the world and passionate about changing the landscape of language
          learning. We work as a team, but we play as a team too, with regular company socials and inspiring
          talks.We know you spend more time at work than you do at home, so we want to make sure you
          enjoy it as much as possible.`,
        title1: `Our teaching approach`,
        paragraphOne: `We believe that anyone can learn a language with EEA’s products. Our bite-size lessons feel
          more like a game than a textbook, and that's by design: Learning is easier when you're having
          fun.`,
        paragraphTwo: `If you’re looking for grammar books, we might not be for you. But if you’re looking for a no-
          pressure way to learn, give us a try.`,
        paragraphThree: `Whether you’re a complete beginner, or a little more advanced, we can help. Our software focuses
          on giving you the confidence to go out and use your language skills – because after all, why learn a
          language if you aren’t going to speak it?`,
      },
    },
    career: {
      hero: { headline: "Career" },
      description: {
        text: `At EEA, we make learning a language easier by combining AI-powered courses and instant feedback from
        our global community. If you love languages, want to work with smart, creative, energetic people and
        possess the initiative, confidence and good judgement to make independent decisions every day, then
        you’ll love working with us!`,
      },
      company: {
        title: "Our company values",
        data: [
          {
            headline: "Trust",
            text: `We give everyone responsibility from day one. We always deliver on
                our promises, and treat each other with respect.`,
          },
          {
            headline: "Effectiveness",
            text: `For us, empowering our customers means solving the right problems –
                then going above and beyond to get stuff done.`,
          },
          {
            headline: "Ambition",
            text: `We always think big and try to go the extra mile – and we pride ourselves
                on leaving a legacy.`,
          },
          {
            headline: "Curiosity",
            text: `We like to question the status quo. We test, learn and share so we
                can better ourselves and our work every day.`,
          },
        ],
      },
      job: {
          title: "Open positions",
          paragraphOneTitle: "TECHNOLOGY",
          paragraphOneLiOne: "IT Specialist",
          paragraphOneLiTwo: "QA Engineer",
          paragraphTwoTitle: "DESIGN",
          paragraphTwoLiOne: "Product Designer",
          paragraphThreeTitle: "BUSINESS",
          paragraphThreeLiOne: "Accountant",
          paragraphThreeLiTwo: "Sales Operations Manager",
          textUp: `Get in touch! We’re always delighted to say hello to a fresh face.`,
          textDown: `We reserve the right to contact only selected individuals.`,
      },
      section: {
          title: "Contact us",
          name: "Name",
          lastName: "Last name",
          email: "e-mail adress",
          message: "Message content",
          submit: "Send",
      },
    },
  };
  