import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__copyright">
        Copyright © 2021 <span className="footer__bold-span">EEA</span> |{" "}
        <a href="/privacy.pdf" className="footer__privacy-policy-link">
          Privacy policy
        </a>
      </p>
    </footer>
  );
}

export default Footer;
