import "./OfferSection.css";
import React, { useState } from "react";
import { InView } from "react-intersection-observer";

function OfferSection({data}) {
  const [inView1, setInView1] = useState(false);
  const [inView2, setInView2] = useState(false);
  const [inView3, setInView3] = useState(false);
  const [inView4, setInView4] = useState(false);

  return (
    <section className="offer-section__container" id="offer">
      <div className="offer-section__content">
        <h2 className="offer-section__page-title">
        {data.title}
        </h2>
        <ul className="offer-section__list">
          <InView onChange={setInView1}>
            <li
              className={
                "offer-section__list-element" + (inView1 ? " active" : "")
              }
            >
                 {data.paragrafOne}
            </li>
          </InView>
          <InView onChange={setInView2}>
            <li
              className={
                "offer-section__list-element offer-section__list-element--even" +
                (inView2 ? " active" : "")
              }
            >
                 {data.paragrafTwo}
            </li>
          </InView>
          <InView onChange={setInView3}>
            <li
              className={
                "offer-section__list-element" + (inView3 ? " active" : "")
              }
            >
                 {data.paragrafThree}
            </li>
          </InView>
          <InView onChange={setInView4}>
            <li
              className={
                "offer-section__list-element offer-section__list-element--even" +
                (inView4 ? " active" : "")
              }
            >
               {data.paragrafFour}
            </li>
          </InView>
        </ul>
      </div>
      <div className="offer-section__slogan-wrapper">
        <p className="offer-section__slogan">
        {data.text}
        </p>
      </div>
    </section>
  );
}

export default OfferSection;
